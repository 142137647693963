import { useUserStore } from '@st/user/stores/useUserStore'
import type { IconName } from '@st/ui/components/StIcon/types'
import type { Category } from '../../types'

export interface MegaTab {
  link: string
  icon?: IconName
  iconRaw?: string
  name: string
  isActive?: boolean
}

export interface useCasinoTabsReturn {
  bottomTabs: ComputedRef<MegaTab[]>
  topTabs: ComputedRef<MegaTab[]>
  topTabsWithProviders: ComputedRef<MegaTab[]>
  isLoading: ComputedRef<boolean>
}

export interface useCasinoTabsParams {
  isLobbyEnabled?: boolean
}

export function useCasinoTabs(
  params: useCasinoTabsParams = {},
): useCasinoTabsReturn {
  const casinoStore = useCasinoStore()
  const { mainCategories, otherCategories, isLoading } =
    storeToRefs(casinoStore)
  const { isAuthenticated } = storeToRefs(useUserStore())
  const { t } = useI18n()
  const route = useRoute()
  const { includes, startsWith } = useRouteMatching()

  const isInMyGames = computed(
    () =>
      includes(route.path, 'casino/favorites') ||
      includes(route.path, 'casino/recent'),
  )

  const isInCategories = computed(() =>
    includes(route.path, 'casino/categories'),
  )

  const topTabs = computed<MegaTab[]>(() => {
    const result: MegaTab[] = []

    if (isAuthenticated.value) {
      result.push({
        link: '/casino/recent',
        name: t('casino.myGamesTab'),
        icon: 'star-solid',
        isActive: isInMyGames.value,
      })
    }
    result.push(
      ...mainCategories.value.map((category: Category) => ({
        link: `/casino/categories/${category.code}`,
        iconRaw: category.icon || undefined,
        name: category.name || category.code,
        isActive: startsWith(
          route.fullPath,
          `/casino/categories/${category.code}`,
        ),
      })),
    )

    return result
  })

  const topTabsWithProviders = computed<MegaTab[]>(() => {
    const tabs = [...topTabs.value]
    tabs.push({
      link: '/casino/providers',
      name: t('casino.providers'),
      icon: 'providers',
      isActive: startsWith(route.fullPath, '/casino/providers'),
    })
    return tabs
  })
  const bottomTabs = computed<MegaTab[]>(() => {
    const result: MegaTab[] = []

    if (isAuthenticated.value && isInMyGames.value) {
      result.push({
        link: '/casino/recent',
        icon: 'circle-clock-solid',
        name: t('casino.recentGames'),
      })
      result.push({
        link: '/casino/favorites',
        icon: 'heart-solid',
        name: t('casino.favorites'),
      })
    }
    if (isInCategories.value) {
      const parentCode = route.params.categoryCode

      const childCategories = otherCategories.value.filter(
        (category: Category) =>
          category.parentCode === parentCode && category.type === 'other',
      )

      if (params.isLobbyEnabled) {
        result.push({
          link: `/casino/categories/${parentCode}`,
          icon: 'cherry',
          name: t('casino.lobby'),
        })
      }

      result.push(
        ...childCategories.map((category: Category) => ({
          link: `/casino/categories/${parentCode}/${category.code}`,
          iconRaw: category.icon || undefined,
          name: category.name || category.code,
          isActive: startsWith(
            route.fullPath,
            `/casino/categories/${parentCode}/${category.code}`,
          ),
        })),
      )
    }

    return result
  })

  return {
    topTabs,
    bottomTabs,
    isLoading,
    topTabsWithProviders,
  }
}
